import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../Context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import { strings } from "../Common/String";
import Button from "../Component/Button";
import { LeadService } from "../Services/LeadService";
import Footer from "../footer";
import Header2 from "../HeaderStatus";
import DropDown from "../Component/DropDown";
import { MasterService } from "../Services/MasterService";
import { PATH } from "../Common/Path";
import MasterSteps from "../Common/steps";

const Relationship = (props) => {
  const {
    steps,
    setSteps,
    setLoading,
    clearSession,
    currentStep,
    setHasChanges,
    setCurrentStep
  } = useContext(AppContext);
  const history = useHistory();
  const [formData, setFormData] = useState({});
  const [apiMessage, setApiMessage] = useState("");
  const [relationships, setRelationships] = useState([]);

  useEffect(() => {
    steps.forEach((step) => {
      if (step.id === currentStep) {
        step.status = 1;
      } else if (step.id < currentStep) {
        step.status = 2;
      }
    });
    setSteps(steps);
  }, [currentStep]);
  
  useEffect(() => {
    setCurrentStep(MasterSteps[2].id);
    setHasChanges(false);
    setLoading(true);
    Promise.all([MasterService.getDropdown("relationship")])
      .then((datas) => {
        if (datas[0] && datas[0].relationship) {
          setRelationships(datas[0].relationship);
          const value = datas[0].relationship.find(
              (d) => d.is_default === true
            ).value
          setFormData((formData) => ({
            ...formData,
            relationShip: value||null,
          }));
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  }, [true]);

  useEffect(() => {
    steps.forEach((step) => {
      if (step.id === currentStep) {
        step.status = 1;
      } else if (step.id < currentStep) {
        step.status = 2;
      }
    });
    setSteps(steps);
  }, [currentStep]);

  /**
   * selectbox onchange
   */
  const handleChange = (event) => {
    if (event.target.name === "ddl_relation") {
      if (event.target.value) {
        setFormData((oldData) => ({
          ...oldData,
          relationShip: event.target.value,
        }));
      }
    }
    setHasChanges(true);
  };

  /**
   * buttonclick
   */
  const aboutYouNextClick = () => {
    // mandatoryValidationSubmit();
    if (formData.relationShip) {
      setLoading(true);
      let body = {
        relationship: formData.relationShip,
      };
      setApiMessage("");
      LeadService.submit_relation(body)
        .then((data) => {
          if (data.success) {
            history.push(PATH.VERIFICATIONDIGILOCK_PAGE);
          } else {
            setApiMessage(data.error);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setLoading(false));
    } else {
    }
  };

  return (
    <div className="inner-section">
      <div className="details">
        <div className="container-fluid">
          <div className="row">
            <Header2 />
            <div className="col-md-9 pl-5 pt-55 pb-5 pr-5">
              <div className="row">
                <div className="col-md-12 text-justify">
                  <h5 className="pb-2">{strings.rel_mainhead}</h5>
                  <p>{strings.rel_subhead}</p>
                </div>

                <DropDown
                  mainclass="col-md-6 pb-3"
                  label=""
                  classes="inuput-box"
                  type="form-control"
                  drId="divRelId"
                  selectid="ddl_relation"
                  options={relationships}
                  onselectchange={handleChange}
                  fieldValue={formData.relationShip||null}
                  disabled={relationships.length === 0}
                />

                <div className="col-md-12 pt-5">
                  <div
                    className={
                      relationships.length === 0
                        ? "alert text-danger text-justify"
                        : "alert alert-success"
                    }
                    role="alert"
                  >
                    {relationships.length === 0
                      ? strings.max_dependency_reached
                      : strings.upload_dependency_proof}
                  </div>
                  <div className="text-justify">
                    <p>{strings.relationnote}</p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="row mh-42">
                    <div className="col-md-12 pt-2">
                      <div className="text-danger text-center api-errormessage">
                        <label id="txterror" name="txterror">
                          {apiMessage}
                        </label>{" "}
                      </div>
                    </div>
                    </div>
                  <div className="row">
                    <div className="col-6 pt-4 "></div>
                    <div className="col-6 pt-3 text-right">
                      <Button
                        classes={
                          relationships.length === 0 ? "inp d-none" : "inp"
                        }
                        classes1="buttonmystyle"
                        label={strings.nextbtn}
                        click={aboutYouNextClick}
                      />
                      {relationships.length === 0 && (
                        <Button
                          classes="inp"
                          label={strings.get_started}
                          classes1="buttonmystyle"
                          click={() => {
                            clearSession();
                            history.push(PATH.MOBILE_PAGE);
                          }}
                          postfix={
                            <i
                              className="fa fa-chevron-right paddingleft"
                              aria-hidden="true"
                            />
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default Relationship;
