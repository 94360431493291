import React, { useEffect, useState } from "react";
import { strings } from "../Common/String";
import AlertBox from "./AlertBox";

function SegmentSlab(props) {
  const { label, handleChange, mainclass, subclass, code, defaultchecked, msg, type, labelClass, options, rupeclass, rupee, rupeelive, checkclass, checksubclass, disabled } = props;
  const [checked, setChecked] = useState([]);
  const [boxes, setBoxes] = useState([]);
  const [showInfo, setShowInfo] = useState(false);
  useEffect(() => {
    setChecked(defaultchecked)
  }, [defaultchecked])

  useEffect(() => {
    if (options && options.length > 0) {
      let boxes = options.map((item, index) => (
        <span key={index}>
          <div className={checksubclass}>
            <label className="sub-add" >{item.label}
              <input type={type} checked={checked.includes(item.code)} onChange={handleCheckBoxChange(item.code)} disabled={disabled} />
              <span className="geekmark"></span>
            </label>
          </div>
          {/* {item.label === "Mutual Funds" ? info() : null} */}
        </span>
      ))
      setBoxes(boxes)
    }
  }, [JSON.stringify(checked), options])

  // const info = () => (
  //   <>
  //     <div className="info-button cursor-pointer" onClick={() => setShowInfo(true)}>
  //       <i className="fa fa-info-circle"></i>
  //     </div>
  //   </>
  // )

  const handleCheckBoxChange = (type) => (event) => {
    let selection = [...checked];
    if (event.target.checked) {
      selection.push(type)
    } else {
      selection = selection.filter(s => s !== type)
    }
    handleChange(code, selection);
    setChecked(selection);
  }

  const handleParentCheck = event => {
    if (event.target.checked) {
      setChecked(options.map(o => o.code))
      handleChange(code, options.map(o => o.code));
    } else {
      setChecked([])
      handleChange(code, []);
    }
  }

  return (
    <>
      <div className={mainclass}>
        <label className={subclass}>{label}
          <input type={type} onChange={handleParentCheck} checked={checked.length > 0} disabled={disabled} />
          <span className={labelClass} />
          <h1> </h1>
          <p>{msg}</p>
          <div className={rupeclass}>
            {
              rupee && (
                rupeelive ? (
                  <>
                    <strike><span>&#x20B9;</span>{rupee}</strike><br />
                    &#x20B9; {rupeelive}
                  </>
                ) : (
                  <>
                    &#x20B9; {rupee}
                  </>
                )
              )
            }
          </div>
        </label>
        <div className={checkclass}>
          {
            boxes
          }
        </div>
      </div>
      {/* <div className="alertContainer" >
        <AlertBox
          show={showInfo}
          showCancelIcon
          className="w-10"
          title={"Note"}
          message={<div className="text-justify -mb-3">
            {strings.segmentInfo}
          </div>
          }
          cancelButtonAction={() => setShowInfo(false)}
        />
      </div> */}
    </>
  );
}

export default SegmentSlab;