import React from "react";

function Footer() {
  return (
    <>
      <div class="straph pl-20">
        <div class="container">
          <div class="row font10">
            <div class="col-md-3">
              Need help? &nbsp;<i class="fa fa-phone" aria-hidden="true"></i>{" "}
              +91 92077 06051
            </div>
            <div className="col-md-3">
              <a
                href="https://www.youtube.com/watch?v=JpJyazUD27Q"
                target="_blank"
                id="font10"
                without="true"
                rel="noreferrer"
              >
                How it works?
              </a>
            </div>
            <div className="col-md-3">Monday to Friday 9 AM to 6 PM </div>
            <div className="col-md-3">
              Follow Us
              <a
                className="s-icin"
                href="https://www.facebook.com/myacumencapital"
                target="_blank"
                without="true"
                rel="noreferrer"
              >
                <i className="fa fa-facebook pl-3 pr-3" aria-hidden="true"></i>
              </a>
              <a
                className="s-icin"
                href="https://www.instagram.com/myacumengroup/"
                target="_blank"
                without="true"
                rel="noreferrer"
              >
                <i className="fa fa-instagram pr-3" aria-hidden="true"></i>
              </a>
              <a
                className="s-icin"
                href="https://www.linkedin.com/company/acumen-capital-market"
                target="_blank"
                without="true"
                rel="noreferrer"
              >
                <i className="fa fa-linkedin pr-3" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <footer className="site-footer">
        <div className="container-fluid">
          <div className="row mt-5 text-md-left">
            <div className="col-md-12 portal">
              <div className="container bottom_border">
                <p className="small">
                  Disclaimer: Acumen Capital Market India Ltd does not guarantee
                  any fixed returns. Investment in the securities market is
                  subject to market risks, read all the related documents
                  carefully before investing.
                </p>
                <p className="small">
                  General queries:{" "}
                  <a href="mailto:care@acumengroup.in">care@acumengroup.in</a> |
                  Grievances (Exchange/DP):{" "}
                  <a href="mailto:grievances@acumengroup.in">
                    grievances@acumengroup.in.
                  </a>{" "}
                  | Procedure to file a complaint on{" "}
                  <a
                    href="https://scores.gov.in/scores/Welcome.html"
                    target="_blank"
                    without="true"
                    rel="noreferrer"
                  >
                    SEBI SCORES
                  </a>{" "}
                  register on the SCORES portal. Mandatory details for filing
                  complaints on SCORES: Name, PAN, Address, Mobile Number,
                  E-mail ID. Benefits: Effective Communication, Speedy redressal
                  of grievances. Please ensure you carefully read{" "}
                  <a
                    href="https://acumengroup.in/disclaimer.aspx#terms_and_conditions"
                    target="_blank"
                    without="true"
                    rel="noreferrer"
                  >
                    {" "}
                    terms & conditions{" "}
                  </a>{" "}
                  &{" "}
                  <a
                    href="https://acumengroup.in/PrivacyAndPolicy.aspx"
                    target="_blank"
                    without="true"
                    rel="noreferrer"
                  >
                    privacy policy.{" "}
                  </a>
                </p>
                <p className="small">
                  Registered office : Acumen Capital Market India Ltd, S.T
                  Reddiar & Sons, Veekshanam Road, Kochi Pin: 682035,
                  0484-4291114 I Website:{" "}
                  <a
                    href="https://www.acumengroup.in"
                    target="_blank"
                    without="true"
                    rel="noreferrer"
                  >
                    www.acumengroup.in{" "}
                  </a>
                  | SEBI Regn. Nos. : INZ000170434 | NSDL Reg no : IN-DP-40-2015
                  | CDSL Reg. no : IN-DP-CDSL-680-2013 | Company Identification
                  Number (CIN) : U67120KL1995PLC008674.
                </p>
              </div>
            </div>
            <div className="container bottom_border text-center mb-3 ftr-privacy">
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.sebi.gov.in/"
                  >
                    SEBI
                  </a>{" "}
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.nseindia.com/"
                  >
                    NSE
                  </a>{" "}
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.bseindia.com/static/investors/services.aspx"
                  >
                    BSE
                  </a>{" "}
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.mcxindia.com/membership/members-compliance/vernacular-language"
                  >
                    MCX
                  </a>{" "}
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.ncdex.com"
                  >
                    NCDEX
                  </a>{" "}
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://nsdl.co.in/"
                  >
                    NSDL
                  </a>{" "}
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.cdslindia.com/"
                  >
                    CDSL
                  </a>{" "}
                </li>
              </ul>
            </div>
          </div>
          <div className="copyright text-center pb-2">
            <p>
              <b>
                Copyright © 2022{" "}
                <a
                  href="https://www.acumengroup.in"
                  target="_blank"
                  without="true"
                  rel="noreferrer"
                >
                  Acumen Capital Market (India) Limited.
                </a>{" "}
                All Rights Reserved.
              </b>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;
