import React from 'react'
import { GoogleLogin } from 'react-google-login';
import { strings } from '../Common/String';
import "bootstrap/dist/css/bootstrap.min.css";

const client_id = "137388426132-b6s1d4j3gdkim8h84raoe3lop31cvbjg.apps.googleusercontent.com";

function GoogleOauth(props) {
    const{onSuccess,onFailure}=props;
    return (
        <div className="text-center">
            <GoogleLogin
                clientId={client_id}
                className="text-center"
                buttonText={strings.signwithgoogle}
                onSuccess={onSuccess}
                onFailure={onFailure}
                cookiePolicy={'single_host_origin'}
                
            />
        </div>
    )
}

export default GoogleOauth
