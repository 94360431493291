import React from "react";

function BrokerageSlab(props) {
    const { label, pclass, plabel, mainclass, spanlabel, subclass,unit, amountclass, offerclass, amount } = props;
    return (
        <div className={mainclass}>
            <div className={subclass}>
                <div className={amountclass}> <span> ₹</span>{amount}<sub>{unit}</sub></div>
                <p className={pclass}>{plabel}</p>
                <div className={{ offerclass }}>{label}<br /> <span>{spanlabel}</span></div>
            </div>
        </div>

    );
}

export default BrokerageSlab;
