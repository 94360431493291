import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { AppContext } from "../../Context/AppContext";
import { strings } from "../../Common/String";
import { LeadService } from "../../Services/LeadService";
import { RadioGroup } from "../../Component/Form/RadioGroup";
import RadioGroupOptions from "../../Common/RadioGroupOptions";
import { array, bool, object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form } from "../../Component/Form";
import { Input } from "../../Component/Form/Input";
import { Dropdown } from "../../Component/Form/Dropdown";
import AlertBox from "../../Component/AlertBox";
import { useFieldArray } from "react-hook-form";
import { Checkbox } from "../../Component/Form/Checkbox";
import Button from "../../Component/Button";
import PincodeBox from "../../Component/Form/PincodeBox";
import { number } from "yup";
import { SummaryContext } from "../Summary/context";
import moment from "moment";

const Nominee = (props) => {
  const { show, cancelButtonAction, onSuccess, maxLimit } = props;
  const { setLoading, setHasChanges, showConfirmDialog, closeConfirmDialog } =
    useContext(AppContext);
  const { nomineeRelationships: ddRelationship } = useContext(SummaryContext);
  const [apiMessage, setApiMessage] = useState(undefined);
  const [visibleApiMesg, setVisibleApiMsg] = useState(false);
  const [majorList, setMajorList] = useState(["Yes"]);
  const [permanentList, setPermanentList] = useState([false]);
  const [nomineeShare, setNomineeShare] = useState([100]);
  const [guardianPermanentList, setGuardianPermanentList] = useState([false]);

  const nomineeRef = useRef(new Array(maxLimit));

  const defOption = useMemo(
    () => ddRelationship.find((d) => d.is_default === true),
    [ddRelationship]
  );

  const schema = object().shape({
    nominees: array()
      .of(
        object().shape({
          name: string().required(strings.rekyc_error),
          mobile: string()
            .required(strings.rekyc_error)
            .min(10, strings.rekyc_mobile_error)
            .max(10, strings.rekyc_mobile_error),
          email: string()
            .email(strings.rekyc_emailerror)
            .required(strings.rekyc_error),
          share: number().required(strings.rekyc_error),
          relationship: string().required(strings.rekyc_error),
          sameAsPermanent: bool(),
          flat: string().when("sameAsPermanent", {
            is: (sameAsPermanent) => !sameAsPermanent,
            then: string().required(strings.rekyc_error),
          }),
          street: string().when("sameAsPermanent", {
            is: (sameAsPermanent) => !sameAsPermanent,
            then: string().required(strings.rekyc_error),
          }),
          area: string().when("sameAsPermanent", {
            is: (sameAsPermanent) => !sameAsPermanent,
            then: string().required(strings.rekyc_error),
          }),
          city: string().when("sameAsPermanent", {
            is: (sameAsPermanent) => !sameAsPermanent,
            then: string(),
          }),
          state: string().when("sameAsPermanent", {
            is: (sameAsPermanent) => !sameAsPermanent,
            then: string(),
          }),
          pincode: string().when("sameAsPermanent", {
            is: (sameAsPermanent) => !sameAsPermanent,
            then: string().required(strings.rekyc_error),
          }),
          country: string().when("sameAsPermanent", {
            is: (sameAsPermanent) => !sameAsPermanent,
            then: string().required(strings.rekyc_error),
          }),
          major: string().required(strings.rekyc_error),
          dob: string().when("major", {
            is: (major) => major === "No",
            then: string()
              .required(strings.rekyc_error)
              .test("minor_check", strings.rekyc_major_error, (value) => {
                return moment(value).isAfter(moment().subtract(18, "years"));
              }),
          }),
          guardianName: string().when("major", {
            is: (major) => major === "No",
            then: string().required(strings.rekyc_error),
          }),
          guardianMobile: string().when("major", {
            is: (major) => major === "No",
            then: string()
              .required(strings.rekyc_error)
              .min(10, strings.rekyc_mobile_error)
              .max(10, strings.rekyc_mobile_error),
          }),
          guardianEmail: string().when("major", {
            is: (major) => major === "No",
            then: string()
              .email(strings.rekyc_emailerror)
              .required(strings.rekyc_error),
          }),
          guardianRelationship: string().when("major", {
            is: (major) => major === "No",
            then: string().required(strings.rekyc_error),
          }),
          guardianSameAsPermanent: bool(),
          guardianAdd1: string().when("major", {
            is: (major, guardianSameAsPermanent) =>
              major === "No" && !guardianSameAsPermanent,
            then: string().required(strings.rekyc_error),
          }),
          guardianAdd2: string().when("major", {
            is: (major, guardianSameAsPermanent) =>
              major === "No" && !guardianSameAsPermanent,
            then: string().required(strings.rekyc_error),
          }),
          guardianAdd3: string().when("major", {
            is: (major, guardianSameAsPermanent) =>
              major === "No" && !guardianSameAsPermanent,
            then: string().required(strings.rekyc_error),
          }),
          guardianCity: string().when("major", {
            is: (major, guardianSameAsPermanent) =>
              major === "No" && !guardianSameAsPermanent,
            then: string(),
          }),
          guardianState: string().when("major", {
            is: (major, guardianSameAsPermanent) =>
              major === "No" && !guardianSameAsPermanent,
            then: string(),
          }),
          guardianPincode: string().when("major", {
            is: (major, guardianSameAsPermanent) =>
              major === "No" && !guardianSameAsPermanent,
            then: string().required(strings.rekyc_error),
          }),
        })
      )
      .min(1)
      .max(4),
  });

  const resolver = yupResolver(schema);

  const registerForm = useForm({
    defaultValues: {
      nominees: [
        {
          name: "",
          mobile: "",
          email: "",
          share: 100,
          relationship: defOption?.value,
          sameAsPermanent: false,
          flat: "",
          street: "",
          area: "",
          city: "",
          state: "",
          pincode: "",
          country: "India",
          major: "Yes",
          dob: "",
          guardianName: "",
          guardianMobile: "",
          guardianEmail: "",
          guardianRelationship: defOption?.value,
          guardianSameAsPermanent: false,
          guardianAdd1: "",
          guardianAdd2: "",
          guardianAdd3: "",
          guardianCity: "",
          guardianState: "",
          guardianPincode: "",
          guardianCountry: "India",
        },
      ],
    },
    resolver,
  });

  useEffect(() => {
    if (defOption) {
      registerForm.setValue(
        "nominees.0.relationship",
        defOption?.value || ddRelationship[0].value
      );
      registerForm.setValue(
        "nominees.0.guardianRelationship",
        defOption?.value || ddRelationship[0].value
      );
    }
  }, [ddRelationship, defOption]);

  useEffect(() => {
    if (
      show &&
      nomineeRef &&
      nomineeRef.current &&
      nomineeRef.current.length > 0
    )
      return nomineeRef.current[1];
  }, [show]);

  const { fields, append, remove } = useFieldArray({
    control: registerForm.control,
    name: "nominees",
  });

  const handlePermanent = (index) => (checked) => {
    setPermanentList((list) => list.map((l, i) => (i === index ? checked : l)));
  };
  const handleGuardianPermanent = (index) => (checked) => {
    setGuardianPermanentList((list) =>
      list.map((l, i) => (i === index ? checked : l))
    );
  };

  const handleMinor = (index) => (selected) => {
    setMajorList((list) => list.map((l, i) => (i === index ? selected : l)));
  };

  const resetForm = () => {
    const checkbox1 = document.getElementById("sameAsPermanent");
    if (checkbox1) {
      setPermanentList([false]);
      checkbox1.checked = false;
    }
    const checkbox2 = document.getElementById("guardianSameAsPermanent");
    if (checkbox2) {
      setGuardianPermanentList([false]);
      checkbox2.checked = false;
    }
    setMajorList(["YES"]);
    setApiMessage(false);
    registerForm.reset({
      nominees: [
        {
          name: "",
          mobile: "",
          email: "",
          share: 100,
          relationship: defOption?.value,
          sameAsPermanent: false,
          flat: "",
          street: "",
          area: "",
          city: "",
          state: "",
          pincode: "",
          country: "India",
          major: "Yes",
          dob: "",
          guardianName: "",
          guardianMobile: "",
          guardianEmail: "",
          guardianRelationship: defOption?.value,
          guardianSameAsPermanent: false,
          guardianAdd1: "",
          guardianAdd2: "",
          guardianAdd3: "",
          guardianCity: "",
          guardianState: "",
          guardianPincode: "",
          guardianCountry: "India",
        },
      ],
    });
  };

  const onCancel = () => {
    resetForm();
    cancelButtonAction();
  };

  const calculateShare = (index = 0) => {
    let nL = registerForm.getValues("nominees");
    if (nL[index].share === "") {
      nL[index].share = 0;
    }

    if (nL.length === 0) {
      return;
    }
    if (nL.length === 1) {
      registerForm.setValue(`nominees.0.share`, 100);
      setNomineeShare((n) => n.map((n, i) => (i === 0 ? 100 : n)));
    }
    if (nL.length === 2 && nL[index].share <= 100) {
      if (index === 0) {
        registerForm.setValue(
          `nominees.1.share`,
          nL[0].share > 0 ? 100 - nL[0].share : 0
        );
      } else {
        registerForm.setValue(
          `nominees.0.share`,
          nL[1].share > 0 ? 100 - nL[1].share : 0
        );
      }
      setNomineeShare([
        registerForm.getValues(`nominees.0.share`),
        registerForm.getValues(`nominees.1.share`),
      ]);
    }
    const totalShare = nL.reduce(
      (sum, next) => sum + parseFloat(next.share || 0),
      0
    );

    if (nL.length > 2) {
      if (nomineeShare.length === nL.length) {
        setNomineeShare((n) =>
          n.map((n, i) =>
            i === index ? registerForm.getValues(`nominees.${index}.share`) : n
          )
        );
      } else {
        setNomineeShare((n) => [...n, nL[index].share]);
      }
      if (totalShare !== 100) {
        nL.forEach((n, i) =>
          registerForm.setError(`nominees.${i}.share`, {
            message: strings.total_share_mimatch,
          })
        );
      } else {
        nL.forEach((n, i) =>
          registerForm.setError(`nominees.${i}.share`, { message: "" })
        );
      }
    }

    if (nL[index].share <= 0) {
      registerForm.setError(`nominees.${index}.share`, {
        message: strings.share_less_than_0,
        type: "validate",
      });
    } else if (nL[index].share > 100) {
      registerForm.setError(`nominees.${index}.share`, {
        message: strings.share_greater_than_100,
      });
    } else {
      registerForm.setError(`nominees.${index}.share`, { message: "" });
    }
    setHasChanges(true);
  };

  const toggleNominee = (e) => {
    let parent = e.target.closest(".accordion");
    parent.classList.toggle("active");
    var panel = parent.nextElementSibling;
    let arrowL = parent.getElementsByTagName("i");
    let name = parent.getElementsByClassName("nom-name")[0];
    let share = parent.getElementsByClassName("nom-share")[0];
    let arrow = arrowL[arrowL.length - 1];
    if (panel.style.display === "block" || panel.style.display === "") {
      panel.style.display = "none";
      arrow.classList.add("fa-chevron-down");
      arrow.classList.remove("fa-chevron-up");
      name.classList.remove("d-none");
      share.classList.remove("d-none");
    } else {
      panel.style.display = "block";
      arrow.classList.add("fa-chevron-up");
      arrow.classList.remove("fa-chevron-down");
      name.classList.add("d-none");
      share.classList.add("d-none");
    }
  };

  const removeNominee = (index) => (e) => {
    e.stopPropagation();
    if (fields.length > 1) {
      showConfirmDialog(
        strings.confirm_remove_nominee_msg,
        undefined,
        () => {
          closeConfirmDialog();
          remove(index);
          majorList.splice(index, 1);
          setMajorList(majorList);
          permanentList.splice(index, 1);
          setPermanentList(permanentList);
        },
        strings.yes,
        strings.no
      );
    }
  };

  const onSubmit = (data) => {
    const totalShare = data.nominees.reduce(
      (sum, next) => sum + parseFloat(next.share || 0),
      0
    );

    if (totalShare === 100) {
      setLoading(true);
      const body = {
        details: {
          nominee: true,
          nominees: data.nominees.map((nominee) => ({
            name: nominee.name,
            mobile: nominee.mobile,
            email: nominee.email,
            share: nominee.share,
            relationship: nominee.relationship,
            addr1: nominee.flat,
            addr2: nominee.street,
            addr3: nominee.area,
            city: nominee.city,
            state: nominee.state,
            country: "India",
            pincode: nominee.pincode,
            major: nominee.major === "Yes",
            dob: nominee.dob,
            guardian_name: nominee.guardianName,
            guardian_mobile: nominee.guardianMobile,
            guardian_email: nominee.guardianEmail,
            guardian_relationship: nominee.guardianRelationship,
            guardian_addr1: nominee.guardianAdd1,
            guardian_addr2: nominee.guardianAdd2,
            guardian_addr3: nominee.guardianAdd3,
            guardian_city: nominee.guardianCity,
            guardian_state: nominee.guardianState,
            guardian_country: "India",
            guardian_pincode: nominee.guardianPincode,
            nominee_addr_same: nominee.sameAsPermanent,
            guardian_addr_same: nominee.guardianSameAsPermanent,
          })),
        },
      };
      setVisibleApiMsg(false);
      setApiMessage("");
      LeadService.rekycNomineeDetails(body)
        .then((data) => {
          if (data.success) {
            onSuccess();
            resetForm();
          } else {
            setVisibleApiMsg(true);
            setApiMessage(data.error);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setLoading(false));
    } else {
      setApiMessage(strings.total_share_100);
    }
  };

  const addRow = () => {
    append({
      name: "",
      mobile: "",
      email: "",
      share: "  ",
      relationship: defOption?.value || ddRelationship[0]?.value,
      sameAsPermanent: false,
      flat: "",
      street: "",
      area: "",
      city: "",
      state: "",
      pincode: "",
      country: "India",
      major: "Yes",
      dob: "",
      guardianName: "",
      guardianMobile: "",
      guardianEmail: "",
      guardianRelationship: defOption?.value || ddRelationship[0]?.value,
      guardianSameAsPermanent: false,
      guardianAdd1: "",
      guardianAdd2: "",
      guardianAdd3: "",
      guardianCity: "",
      guardianState: "",
      guardianPincode: "",
      guardianCountry: "India",
    });
    setPermanentList((list) => [...list, false]);
    setMajorList((old) => [...old, "Yes"]);
    setNomineeShare((old) => [...old, 0]);
    setGuardianPermanentList((old) => [...old, false]);
  };

  const onError = (data) => {
    // console.log(data.nominees)
    // if (!isEmpty(data.nominees)) {
    //   data.nominees.forEach((nominee, index) => {
    //     if (nominee.name) {
    //       var field = document.getElementById(`nominees[${index}].name`);
    //       // field.scrollIntoView({
    //       //   behavior: "smooth",
    //       // });
    //       window.scrollTo(0, field.offsetTop)
    //       return
    //     }
    //   })
    // }
  };

  return (
    <div className="loaderContainers">
      <AlertBox
        show={show}
        title={
          <>
            <div className="ml-4 mt-3 w-75">
              <h5 className="update-label">{strings.rekyc_update_label}</h5>
            </div>
            <hr className="mb-2" />
          </>
        }
        showCancelIcon={true}
        cancelButtonAction={onCancel}
        classes="box-sectionx"
      >
        <div className="container" id="pd-50">
          <Form
            autoFocusElement="name"
            reactHookForm={registerForm}
            onSubmit={onSubmit}
            onError={onError}
          >
            <div className="nominee-box">
              {fields.map((nominee, index) => {
                let tabIndex = index * 30;
                return (
                  <div key={index}>
                    <button
                      type="button"
                      className="accordion"
                      onClick={toggleNominee}
                      style={
                        index % 2 === 0 ? {} : { backgroundColor: "#f8f8f8" }
                      }
                    >
                      <span className="f-1">Nominee {index + 1}</span>
                      <span className="nom-name f-1 d-none">
                        {nominee.name}
                      </span>
                      <span className="nom-share pr-3 d-none">
                        Share: {nomineeShare[index] || 0}%
                      </span>
                      <i
                        className={`fa fa-trash-o pr-4 mt-1 ${index !== 0 ? "" : " vh"
                          }`}
                        aria-hidden="true"
                        onClick={removeNominee(index)}
                      ></i>
                      <i
                        className="fa fa-chevron-up mt-1"
                        aria-hidden="true"
                      ></i>
                    </button>
                    <div
                      className="panel pr-0"
                      style={
                        index % 2 === 0 ? {} : { backgroundColor: "#f8f8f8" }
                      }
                    >
                      <div className="row pt-2">
                        <div className="col-md-4">
                          <Input
                            reference={(ref) => nomineeRef.current.push(ref)}
                            tabIndex={`${tabIndex + 131}`}
                            mainClass="pb-2"
                            classes="material-textfield"
                            label={strings.rekyc_nominee_name}
                            placeholder=""
                            name="name"
                            inputClass="addinput inpzz"
                            type="text"
                            maxLength={50}
                            parentNode="nominees"
                            index={index}
                            labelClass="lblz"
                            errorclass="text-danger errorfont mh-18"
                          />
                        </div>
                        <div className="col-md-4">
                          <Input
                            tabIndex={`${tabIndex + 132}`}
                            mainClass="pb-2"
                            label={strings.rekyc_nominee_mobile_no}
                            labelClass="lblz"
                            placeholder=""
                            classes="material-textfield"
                            inputClass="addinput inpzz"
                            type="number"
                            name="mobile"
                            maxLength={10}
                            parentNode="nominees"
                            index={index}
                            errorclass="text-danger errorfont mh-18"
                          />
                        </div>
                        <div className="col-md-4">
                          <Input
                            tabIndex={`${tabIndex + 133}`}
                            mainClass=""
                            label={strings.rekyc_nominee_email}
                            labelClass="lblz"
                            placeholder=""
                            classes="material-textfield"
                            inputClass="addinput inpzz"
                            parentNode="nominees"
                            index={index}
                            type="email"
                            name="email"
                            maxLength={50}
                            errorclass="text-danger errorfont mh-18"
                          />
                        </div>
                        <div className="col-md-4 mt-2">
                          <Input
                            tabIndex={`${tabIndex + 134}`}
                            mainClass=""
                            label={strings.nominee_percentage_share}
                            labelClass="lblz"
                            placeholder=""
                            parentNode="nominees"
                            index={index}
                            classes="material-textfield"
                            inputClass="addinput inpzz"
                            type="number"
                            maxLength={3}
                            name="share"
                            onChange={() => calculateShare(index)}
                            errorclass="text-danger errorfont mh-18"
                          />
                        </div>
                        <div className="col-md-4 mt-2">
                          <Dropdown
                            tabIndex={`${tabIndex + 135}`}
                            mainClass=""
                            classes="material-textfield"
                            label={strings.rekyc_relation_label}
                            labelClass="lbltransforms lblz"
                            selectClass="addinput inpzz pb-2 p-2"
                            name="relationship"
                            parentNode="nominees"
                            index={index}
                            options={ddRelationship}
                            errorclass="text-danger errorfont mh-18"
                          />
                        </div>
                        <div className="col-md-12 pt-1">
                          <label>Nominee Address</label>
                        </div>
                        <div className="col-md-12">
                          <Checkbox
                            type="checkbox"
                            classes="checkbox-colour"
                            index={index}
                            onChange={handlePermanent(index)}
                            parentNode="nominees"
                            inputClass="segchks1"
                            name="sameAsPermanent"
                            tabIndex={`${tabIndex + 136}`}
                            label={strings.sameaspermaddress}
                            labelClass="segchks2"
                            errorclass="text-danger"
                          />
                        </div>
                        <div className="col-md-12">
                          {!permanentList[index] && (
                            <div className="row">
                              <div className="col-md-4">
                                <Input
                                  tabIndex={`${tabIndex + 137}`}
                                  mainClass="pt-2"
                                  label={strings.address_flat}
                                  labelClass="lblz nom-lbl"
                                  placeholder=""
                                  parentNode="nominees"
                                  index={index}
                                  classes="material-textfield"
                                  inputClass="addinput inpzz"
                                  type="text"
                                  name="flat"
                                  maxLength={50}
                                  errorclass="text-danger errorfont mh-18"
                                />
                              </div>
                              <div className="col-md-4">
                                <Input
                                  tabIndex={`${tabIndex + 138}`}
                                  mainClass="pt-2"
                                  label={strings.address_street}
                                  labelClass="lblz"
                                  placeholder=""
                                  parentNode="nominees"
                                  index={index}
                                  classes="material-textfield"
                                  inputClass="addinput inpzz"
                                  type="text"
                                  name="street"
                                  maxLength={50}
                                  errorclass="text-danger errorfont mh-18"
                                />
                              </div>
                              <div className="col-md-4">
                                <Input
                                  tabIndex={`${tabIndex + 139}`}
                                  mainClass="pt-2"
                                  label={strings.address_area}
                                  labelClass="lblz"
                                  placeholder=""
                                  parentNode="nominees"
                                  index={index}
                                  classes="material-textfield"
                                  inputClass="addinput inpzz"
                                  type="text"
                                  name="area"
                                  maxLength={50}
                                  errorclass="text-danger errorfont mh-18"
                                />
                              </div>
                              <div className="col-md-4">
                                <PincodeBox
                                  tabIndex={`${tabIndex + 140}`}
                                  mainclass="pt-2"
                                  placeholder=""
                                  inputclass="addinput inpzz"
                                  type="number"
                                  name="pincode"
                                  stateName="state"
                                  cityName="city"
                                  label="Pincode"
                                  parentNode="nominees"
                                  index={index}
                                  labelClass="lblz"
                                  errorclass="text-danger errorfont pl-3"
                                />
                              </div>
                              <div className="col-md-4 mt-2">
                                <Input
                                  tabIndex={`${tabIndex + 141}`}
                                  mainClass=""
                                  placeholder=""
                                  label={strings.city}
                                  labelClass="lblz"
                                  classes="material-textfield"
                                  inputClass="addinput inpzz"
                                  type="text"
                                  parentNode="nominees"
                                  index={index}
                                  name="city"
                                  maxLength={50}
                                  disabled={true}
                                  errorclass="text-danger errorfont mh-18"
                                />
                              </div>
                              <div className="col-md-4 mt-2">
                                <Input
                                  tabIndex={`${tabIndex + 142}`}
                                  mainClass="pb-2"
                                  placeholder=""
                                  label={strings.state}
                                  classes="material-textfield"
                                  inputClass="addinput inpzz"
                                  type="text"
                                  parentNode="nominees"
                                  index={index}
                                  labelClass="lblz"
                                  name="state"
                                  textId="state"
                                  maxLength={50}
                                  disabled={true}
                                  errorclass="text-danger errorfont mh-18"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="col-md-12 pb-3 pl-4">
                          <div className="flex">
                            <RadioGroup
                              tabIndex={`${tabIndex + 143}`}
                              index={index}
                              parentNode="nominees"
                              onChange={handleMinor(index)}
                              classes="radioz"
                              labelClass="rlbl pl-1 pr-3"
                              name="major"
                              mainLabel={strings.minor_nominee_label}
                              mainLabelClass="nom_radio pr-5 pl-1"
                              options={RadioGroupOptions.ADD_NOMINEE || []}
                              errorclass="text-danger errorfont mh-18"
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          {majorList[index] === "No" ? (
                            <div className="row">
                              <div className="col-md-4">
                                <Input
                                  tabIndex={`${tabIndex + 144}`}
                                  mainClass="pt-2"
                                  label={strings.dateofbirth}
                                  labelClass="lblz"
                                  classes="material-textfield"
                                  placeholder=""
                                  parentNode="nominees"
                                  index={index}
                                  inputClass="addinput inpzz"
                                  name="dob"
                                  maxLength={50}
                                  type="date"
                                  errorclass="text-danger errorfont mh-18"
                                />
                              </div>
                              <div className="col-md-4">
                                <Input
                                  tabIndex={`${tabIndex + 145}`}
                                  mainClass="pt-2"
                                  label={strings.rekyc_nominee_guardian_name}
                                  labelClass="lblz"
                                  classes="material-textfield"
                                  placeholder=""
                                  parentNode="nominees"
                                  index={index}
                                  inputClass="addinput inpzz"
                                  name="guardianName"
                                  maxLength={50}
                                  type="text"
                                  errorclass="text-danger errorfont mh-18"
                                />
                              </div>
                              <div className="col-md-4 mt-2">
                                <Input
                                  tabIndex={`${tabIndex + 146}`}
                                  mainClassName="pt-2"
                                  label={
                                    strings.rekyc_nominee_guardian_mobile_no
                                  }
                                  labelClass="lblz nom-lbl"
                                  classes="material-textfield"
                                  placeholder=""
                                  parentNode="nominees"
                                  index={index}
                                  inputClass="addinput inpzz"
                                  name="guardianMobile"
                                  type="number"
                                  maxLength={10}
                                  errorclass="text-danger errorfont mh-18"
                                />
                              </div>
                              <div className="col-md-4 mt-2">
                                <Input
                                  tabIndex={`${tabIndex + 147}`}
                                  mainClassName="pb-3"
                                  label={strings.rekyc_nominee_guardian_email}
                                  labelClass="lblz nom-lbls"
                                  classes="material-textfield"
                                  placeholder=""
                                  parentNode="nominees"
                                  index={index}
                                  inputClass="addinput inpzz"
                                  name="guardianEmail"
                                  type="text"
                                  maxLength={50}
                                  errorclass="text-danger errorfont mh-18"
                                />
                              </div>
                              <div className="col-md-4 mt-2">
                                <Dropdown
                                  tabIndex={`${tabIndex + 148}`}
                                  mainClassName=""
                                  name="guardianRelationship"
                                  classes="material-textfield"
                                  label={strings.rekyc_relation_label}
                                  labelClass="lbltransforms lblz"
                                  selectClass="addinput inpzz pb-2 p-2"
                                  parentNode="nominees"
                                  index={index}
                                  options={ddRelationship}
                                  errorclass="text-danger errorfont mh-18"
                                />
                              </div>
                              <div className="col-md-12 pt-3">
                                <label>Guardian Address</label>
                              </div>
                              <div className="col-md-12">
                                <Checkbox
                                  type="checkbox"
                                  classes="checkbox-colour"
                                  index={index}
                                  onChange={handleGuardianPermanent(index)}
                                  parentNode="nominees"
                                  inputClass="segchks1"
                                  name="guardianSameAsPermanent"
                                  tabIndex={`${tabIndex + 149}`}
                                  label={strings.sameaspermaddress}
                                  labelClass="segchks2"
                                  errorclass="text-danger"
                                />
                              </div>
                              <div className="col-md-12">
                                {!guardianPermanentList[index] && (
                                  <div className="row pt-1">
                                    <div className="col-md-4">
                                      <Input
                                        tabIndex={`${tabIndex + 150}`}
                                        mainClass="pt-2"
                                        label={strings.address_flat}
                                        labelClass="lblz nom-lbl"
                                        parentNode="nominees"
                                        index={index}
                                        classes="material-textfield"
                                        placeholder=""
                                        inputClass="addinput inpzz"
                                        name="guardianAdd1"
                                        type="text"
                                        maxLength={50}
                                        errorclass="text-danger errorfont mh-18"
                                      />
                                    </div>
                                    <div className="col-md-4">
                                      <Input
                                        tabIndex={`${tabIndex + 151}`}
                                        mainClass="pt-2"
                                        parentNode="nominees"
                                        index={index}
                                        label={strings.address_street}
                                        labelClass="lblz"
                                        classes="material-textfield"
                                        placeholder=""
                                        inputClass="addinput inpzz"
                                        name="guardianAdd2"
                                        type="text"
                                        maxLength={50}
                                        errorclass="text-danger errorfont mh-18"
                                      />
                                    </div>
                                    <div className="col-md-4">
                                      <Input
                                        tabIndex={`${tabIndex + 152}`}
                                        mainClass="pt-2"
                                        parentNode="nominees"
                                        index={index}
                                        label={strings.address_area}
                                        labelClass="lblz"
                                        classes="material-textfield"
                                        placeholder=""
                                        inputClass="addinput inpzz"
                                        name="guardianAdd3"
                                        type="text"
                                        maxLength={50}
                                        errorclass="text-danger errorfont mh-18"
                                      />
                                    </div>
                                    <div className="col-md-4">
                                      <PincodeBox
                                        tabIndex={`${tabIndex + 153}`}
                                        mainclass="pt-2"
                                        placeholder=""
                                        inputclass="addinput inpzz"
                                        type="number"
                                        name="guardianPincode"
                                        stateName="guardianState"
                                        cityName="guardianCity"
                                        index={index}
                                        parentNode="nominees"
                                        label="Pincode"
                                        labelClass="lblz"
                                        errorclass="text-danger errorfont pl-3"
                                      />
                                    </div>
                                    <div className="col-md-4 mt-2">
                                      <Input
                                        tabIndex={`${tabIndex + 154}`}
                                        mainclass="pb-2"
                                        parentNode="nominees"
                                        index={index}
                                        label={strings.city}
                                        labelClass="lblz"
                                        classes="material-textfield"
                                        inputClass="addinput inpzz"
                                        type="text"
                                        name="guardianCity"
                                        maxLength={50}
                                        disabled={true}
                                        errorclass="text-danger errorfont mh-18"
                                      />
                                    </div>
                                    <div className="col-md-4 mt-2">
                                      <Input
                                        tabIndex={`${tabIndex + 155}`}
                                        mainClass="pb-2"
                                        parentNode="nominees"
                                        index={index}
                                        label={strings.state}
                                        labelClass="lblz"
                                        classes="material-textfield"
                                        inputClass="addinput inpzz"
                                        type="text"
                                        maxLength={50}
                                        name="guardianState"
                                        disabled={true}
                                        errorclass="text-danger errorfont mh-18"
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              {fields.length < maxLimit ? (
                <div className="col-md-12 pt-1">
                  <button
                    type="button"
                    className="button-link"
                    onClick={addRow}
                  >
                    <i
                      className="fa fa-plus-circle pr-2"
                      aria-hidden="true"
                    ></i>
                    Add nominee
                  </button>
                </div>
              ) : null}
            </div>
            {apiMessage ? (
              <div className="row">
                <div className="col-md-12">
                  <div className="row mh-42">
                    <div className="col-md-12 pt-2">
                      {visibleApiMesg && (
                        <div className="text-danger text-center">
                          <label id="txterror" name="txterror">
                            {apiMessage}
                          </label>{" "}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="row">
              <div className="col-md-8 justify-content-start align-items-start ml-0">
                <div className="alert alert-success mt-3" role="alert">
                  {strings.rekyc_proof_disclaimer}
                </div>
              </div>
              <div className=" row col-md-4 justify-content-end align-items-end mr-0">
                <Button
                  type="reset"
                  classes1="cancelbtn mt-0"
                  click={onCancel}
                  label="Cancel"
                />
                <Button
                  tabIndex="156"
                  type="submit"
                  classes1="inps addsbtn mt-0"
                  label="SUBMIT"
                />
              </div>
            </div>
          </Form>
        </div>
      </AlertBox>
    </div>
  );
};
export { Nominee };
