import React, { useContext, useEffect, useState } from "react";
import { strings } from "../Common/String";
import { AppContext } from "../Context/AppContext";
import Footer from "../footer";
import { LeadService } from "../Services/LeadService";

const ThankYou = (props) => {
  const { setLoading } = useContext(AppContext);
  const [apiMessage, setApiMessage] = useState("");
  const [name, setName] = useState(null);
  const [minTime, setMintime] = useState(null);
  const [maxTime, setMaxTime] = useState(null);

  useEffect(() => {
    setLoading(true);
    setApiMessage("");
    LeadService.getEndLeadStatus()
      .then((data) => {
        if (data.success) {
          setName(data.name);
          setMintime(data.min_time);
          setMaxTime(data.max_time);
        } else {
          setApiMessage(data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className="details">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 head-section   pb-3">
            <div className="log-main  pt-4 pl-4">
              {/* <a href="#"> */}
              <img
                src={process.env.PUBLIC_URL + "/img/acumen-logo.png"}
                alt=""
              />
              {/* </a> */}
            </div>
          </div>
          <div className="col-md-12 pl-3 pt-5 pb-5 pr-3 pl-md-5 pr-md-5">
            <div className="row">
              <div className="col-md-2 text-center" />
              <div className="col-md-8 bg-w text-center">
                <p>
                  <img
                    src={process.env.PUBLIC_URL + "/img/check1.png"}
                    alt=""
                  />
                </p>
                <h6 className="pb-3 thank">
                  {strings.thankyou} {name}!
                </h6>
                <p className="pt-1 mb-0 thankp">{strings.thankyoupara1}.</p>
                <p className=" pt-3 mb-0 thankp">
                  {strings.taketime}
                  {minTime}-{maxTime}
                  {strings.taketime1A}
                </p>
                <p className=" pt-3 mb-0 thankp">
                  <h6>{strings.signin_check_status}</h6>
                </p>
                <h6 className="pt-3 head-ov"> </h6>
                <div className="col-md-12 pt-3">
                  <div className="row">
                    <div className="col-md-2 pt-4" />
                    <div className="col-md-8 pt-3 text-center">
                      <div className="inp ">
                        <a
                          href="https://touchbroking.com/"
                          style={{ textDecoration: "none" }}
                          target="blank"
                        >
                          {strings.gotowebsit}
                        </a>
                        <br></br>
                        <br></br>
                        {/* <p>
                          <c>
                            <h6>{strings.headoffice}</h6>
                            {strings.officeaddress1}<br></br>
                            {strings.officeaddress2}<br></br>
                            {strings.officeaddress3}
                          </c>
                        </p> */}
                      </div>
                    </div>
                    <div className="col-md-2 pt-4" />
                    <div className="col-md-2 pt-4" />
                    <div className="col-md-3 pt-3 d-none">
                      <div className="text-danger text-center">
                        <label id="txterror" className="txterror">
                          {apiMessage}
                        </label>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2 text-center" />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default ThankYou;
