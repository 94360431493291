import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../../Context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import { strings } from "../../Common/String";
import Button from "../../Component/Button";
import { LeadService } from "../../Services/LeadService";
import TextBox from "../../Component/TextField";
import OTPInput from "../../Component/OTPInput";
import Count from "../../Component/CountComponent/Count";
import { getPath } from "../../Common/utils";
import EditPen from "../../Component/EditPen";
import SignInButton from "../../Component/SignInButton";
import { PATH } from "../../Common/Path";
import Footer from "../Footer";

const Signin = (props) => {
  const {
    setToken,
    setLoading,
    showConfirmDialog,
    closeConfirmDialog
  } = useContext(AppContext);
  const history = useHistory();
  const [formData, setFormData] = useState({});
  const [showresendotp, setshowresendotp] = useState(false);
  const [otp, setOtp] = useState("");
  const [showOTPBlock, setShowOTPBlock] = useState(false);
  const [showErrorOtpSec, setShowErrorOtpSec] = useState(false);
  const [restartTimer, setRestartTimer] = useState(0);
  const [showpencil, setShowpencil] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const clientCodeRef = useRef();
  const otpRef = useRef();

  //------------for hide and visible- otp--------------------
  const [visibleContinue, setVisibleContinue] = useState(true);
  const [visibleSubmit, setVisibleSubmit] = useState(false);

  useEffect(() => {
    if (clientCodeRef && clientCodeRef.current) clientCodeRef.current.focus();
  }, [clientCodeRef]);

  const validateForm = () => {
    let isValid = true;
    var id = formData.clientCodeOrPANForOTP;
    if (!formData.clientCodeOrPANForOTP) {
      setShowErrorOtpSec(strings.mandatory);

      isValid = false;
    } else {
      if (id && id.length > 3) {
        setShowErrorOtpSec("");
      } else {
        isValid = false;
        setShowErrorOtpSec(strings.clientcodePANError);
      }
    }
    return isValid;
  };

  const handleChange = (event) => {
    setShowErrorOtpSec("");
    if (event.target.name === "clientCodeOrPANForOTP") {
      setFormData((oldData) => ({
        ...oldData,
        clientCodeOrPANForOTP: event.target.value,
      }));
      setShowErrorOtpSec("");
    }

    if (event.target.name === "otp") {
      setFormData((oldData) => ({ ...oldData, otp: event.target.value }));
    }
  };

  /**
   * conter function
   */
  const counterFinished = () => {
    setshowresendotp(true);
    //  console.log("Counter Finished");
  };

  /**
   * otp validataion
   * @param {} e
   */
  const onOTPchange = (e) => {
    setShowErrorOtpSec("");
    if (e.target.value.length !== null) {
      setOtp(e.target.value);
      // setIsError(false);
    } else {
      setOtp("");
      //setIsError(true);
    }
  };
  /**
   * request otp
   * @param {*} type
   */
  const requestOTP = () => {
    if (validateForm()) {
      setLoading(true);
      setShowErrorOtpSec("");
      LeadService.rekycRequestOTP(formData.clientCodeOrPANForOTP)
        .then((data) => {
          if (data.success) {
            setDisabled(true);
            setShowpencil(true);
            setShowOTPBlock(true);
            setVisibleSubmit(true);
            setVisibleContinue(false);
            setRestartTimer((old) => old + 1);
            otpRef.current.focus();
            setFormData((msg) => ({ ...msg, message: data.message }));
          } else {
            setShowErrorOtpSec(data.error);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setLoading(false));
    }
  };
  /**
   * OTP VALIDATION
   * @returns
   */

  const validateOTP = () => {
    let isValid = true;
    if (!otp) {
      isValid = false;
      setShowErrorOtpSec(strings.please_enter_otp);
    } else {
      isValid = true;
      setShowErrorOtpSec("");
    }
    return isValid;
  };

  const errorMsgPopup = () => {
    showConfirmDialog(
      <>
        <div><b>{strings.rekyc_error_msg}</b></div><br />
        <div dangerouslySetInnerHTML={{ __html: formData.error }} /><br />
        <div className="color-lightgray error_code">Error code - {formData.error_code}</div>
      </>,
      undefined,
      undefined,
      strings.ok,
      undefined,
      () => {
        window.location.reload();
        closeConfirmDialog();
      }
    );
  }

  /**
   * otp validation
   */
  const handleOTPSubmit = () => {
    if (formData.clientCodeOrPANForOTP && validateOTP()) {
      setLoading(true);
      setShowErrorOtpSec("");
      LeadService.rekycValidateOTP(formData.clientCodeOrPANForOTP, otp)
        .then((data) => {
          if (data.success) {
            setToken(data.token);
            history.push(getPath(data.next_step));
          }
          // if PAN or mobile or email has not updated
          else if (data.error_code && data.error_code === "027-002-001") {
            errorMsgPopup(formData.error = data.error, formData.error_code = data.error_code);
          }
          else {
            setShowErrorOtpSec(data.error);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setLoading(false));
    }
  };

  /**
   * RESEND OTP
   */
  const resendOTP = () => {
    // setShowOTPBlock(false);
    setshowresendotp(false);
    requestOTP();
  };

  // /**
  //  * signup
  //  */
  const onSignUpClick = () => {
    history.push(`${PATH.MOBILE_PAGE}`);
  };

  const handleEdit = (event) => {
    event.preventDefault();
    setshowresendotp(false);
    setShowOTPBlock(false);
    setVisibleSubmit(false);
    setVisibleContinue(true);
    setShowpencil(false);
    setDisabled(false);
    setShowErrorOtpSec("");
  };

  return (
    <>
      <div className="header pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="logo-align">
                <img
                  src={process.env.PUBLIC_URL + "/img/acumen-logo.png"}
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-6" />
          </div>
        </div>
      </div>
      <div className="inner">
        <div className="container">
          <div className="row">
            <div className="col-md-50 text-center">
              <div className="images-add">
                <img src={process.env.PUBLIC_URL + "/img/Acumen.gif"} alt="" />
              </div>
            </div>
            <div className="col-md-50 pt-3 pt-lg-5 mb-5">
              <div className="s-box pt-5 pb-5">
                <h1 className="text-center pb-2">
                  {strings.rekyc_signin_title}
                </h1>
                <div className="tabcontent mt-4">
                  <TextBox
                    mainclass="inp pt-3"
                    placehold={strings.clientCodeOrPAN}
                    type="text"
                    tabIndex="1"
                    reference={clientCodeRef}
                    textId="clientCodeOrPANForOTP"
                    inputclass="txt-up"
                    fieldValue={formData.clientCodeOrPANForOTP}
                    onTextChange={handleChange}
                    disabled={disabled}
                    postfix={
                      showpencil && (
                        <EditPen
                          classes={"edit-btn1 icon-topsignin"}
                          imgsrc={process.env.PUBLIC_URL + "/img/pen.png"}
                          imgclass="signinpencil"
                          click={handleEdit}
                        />
                      )
                    }
                  />
                  {showOTPBlock && (
                    <OTPInput
                      divClasses="inp pt-3"
                      maxLength="6"
                      classes={"mb-0"}
                      textType="number"
                      otpPlaceholder={strings.otpenter}
                      onChange={onOTPchange}
                      holder={String.otpEnter}
                      textId={otp}
                      tabIndex="3"
                      reference={otpRef}
                      errorclass="text-danger errorfont"
                      errorMessage={strings.mandatory}
                      postfix={
                        <div className="time-otp">
                          <Count
                            onFinish={counterFinished}
                            restart={restartTimer}
                          />
                        </div>
                      }
                    />
                  )}
                  <section
                    id="txterror"
                    name="txterror"
                    className="text-danger api-errormessage mh-0"
                  >
                    {showErrorOtpSec}
                  </section>
                  <section className="text-secondary api-errormessage pt-3">
                    {showOTPBlock && formData.message}
                  </section>
                  {showresendotp && (
                    <div className="pt-1">
                      <p className="mb-0">
                        {strings.cmn_otp}{" "}
                        <a href="# " onClick={resendOTP}>
                          {strings.resend}{" "}
                        </a>
                      </p>
                    </div>
                  )}

                  {visibleContinue && (
                    <Button
                      classes="inp pt-2"
                      label={strings.continue}
                      click={requestOTP}
                      inputType="text"
                      tabIndex="2"
                      classes1="buttonmystyle"
                    />
                  )}
                  {visibleSubmit && (
                    <Button
                      classes="inp pt-2"
                      tabIndex="4"
                      label={strings.signin}
                      click={handleOTPSubmit}
                      inputType="text"
                    />
                  )}
                </div>

                <div className="or-devide">
                  <hr />
                  <span>or</span>
                </div>
                <div className="continue-with">
                  <p className="text-center mb-0">{strings.donthaveaccount}</p>
                  <SignInButton
                    classes={"sign-b"}
                    tabIndex="5"
                    label={strings.signup}
                    onclick={onSignUpClick}
                  ></SignInButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Signin;
