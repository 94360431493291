import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../Context/AppContext";
import { strings } from "../../Common/String";
import Button from "../../Component/Button";
import { LeadService } from "../../Services/LeadService";
import RadioGroupOptions from "../../Common/RadioGroupOptions";
import PennydropSpin from "../../Component/PennydropSpin";
import { Form } from "../../Component/Form";
import { useForm } from "react-hook-form";
import { bool, object, string, number } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from "../../Component/Form/Input";
import { RadioGroup } from "../../Component/Form/RadioGroup";
import AlertBox from "../../Component/AlertBox";
import PincodeBox from "../../Component/Form/PincodeBox";
import { Checkbox } from "../../Component/Form/Checkbox";
import useScript from "../../useScript";

const BankDetails = (props) => {
  useScript("https://app.digio.in/sdk/v9/digio.js");
  let digio = null;

  const { show, cancelButtonAction, onSuccess } = props;
  const { setLoading } = useContext(AppContext);

  const [showPD, setShowPD] = useState(false);
  const [showRPD, setShowRPD] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [apiMessage, setApiMessage] = useState("");
  const [disabledFields, setDisabledFields] = useState([]);
  const [showDPCheckbox, setShowDPCheckbox] = useState(false);
  const [visibleVerifyBtn, setVisibleVerifyBtn] = useState(true);
  const [visibleSubmitBtn, setVisibleSubmitBtn] = useState(false);
  const [visibleBankMainAddress, setVisibleBankMainAddress] = useState(false);
  const [visibleBankName, setVisibleBankName] = useState(false);
  const [visibleMICR, setVisibleMICR] = useState(false);
  const [visibleAccHolder, setVisibleAccHolder] = useState(false);
  const [accNameDisabled, setAccNameDisabled] = useState(false);
  const [showPennydrop, setShowPennydrop] = useState(false);
  const [showPenny, setShowPenny] = useState(false);
  const [bankDetails, setBankDetails] = useState({
    accountHolderName: '',
    accountNumber: '',
    accountType: '',
    bankAddress1: '',
    bankAddress2: '',
    bankAddress3: '',
    bankCity: '',
    bankCountry: '',
    bankFetched: '',
    bankIFSC: '',
    bankMICR: '',
    bankName: ' ',
    bankPincode: '',
    bankState: '',
    matchScore: '',
    matched: '',
    message: '',
    micrFetched: '',
    reversePennyDrop: 0,
  });

  const accnoRef = useRef();
  const micrRef = useRef();

  const schema = object().shape({
    txtAccType: string().required(strings.rekyc_error),
    txtAccNo: string().required(strings.rekyc_error),
    txtIFC_code: string().required(strings.rekyc_error),
    txtAccHolder: string().test("required", strings.rekyc_error, (value) => {
      return visibleAccHolder && !accNameDisabled
        ? value && value.length > 0
        : true;
    }),
    txtMICE: string().test("required", strings.rekyc_error, (value) => {
      return visibleMICR ? value && value.length > 0 : true;
    }),
    txtBank: string().test("required", strings.rekyc_error, (value) => {
      return visibleBankName ? value && value.length > 0 : true;
    }),
    txtBuilding: string().test("required", strings.rekyc_error, (value) => {
      return visibleBankMainAddress ? value && value.length > 0 : true;
    }),
    txtStreet: string()
      .nullable()
      .test("required", strings.rekyc_error, (value) => {
        return visibleBankMainAddress ? value && value.length > 0 : true;
      }),
    txtLocality: string().nullable(),
    bankPincode: string()
      .nullable()
      .test("required", strings.rekyc_error, (value) => {
        return visibleBankMainAddress ? value && value.length > 0 : true;
      }),
    bankCity: string(),
    bankState: string(),
    markAsPrimary: bool(),
    markAsPrimaryInDP: bool(),
    penny_drop: number(),
    reverse_penny_drop: number()
  });

  const resolver = yupResolver(schema);

  const registerForm = useForm({
    defaultValues: {
      txtAccType: "",
      txtAccNo: "",
      txtAccHolder: "",
      txtIFC_code: "",
      txtMICE: "",
      txtBank: "",
      txtBuilding: "",
      txtStreet: "",
      txtLocality: "",
      bankPincode: "",
      bankCity: "",
      bankState: "",
      markAsPrimary: false,
      markAsPrimaryInDP: false,
      penny_drop: 0,
      reverse_penny_drop: 0,
    },
    resolver,
  });

  useEffect(() => {
    if (show && accnoRef && accnoRef.current) accnoRef.current.focus();
  }, [show]);

  useEffect(() => {
    setLoading(true);
    LeadService.getRekycSummary()
      .then((data) => {
        if (data.success) {
          if (data.show_bank_options.penny_drop === true) {
            setShowPD(true);
          }
          if (data.show_bank_options.reverse_penny_drop === true) {
            setShowRPD(true);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  }, []);


  // /**
  //  * cverify click
  //  */
  const verifyBank = (data) => {
    setShowPennydrop(true);
    // setLoading(true);
    let details = {
      bank_account_type: data.txtAccType,
      bank_account_no: data.txtAccNo,
      bank_ifsc: data.txtIFC_code,
    };
    LeadService.rekycVerifyBankAccount({ details })
      .then((response) => {
        if (response.success) {
          setDisabledFields(["txtAccType", "txtAccNo", "txtIFC_code"]);
          if (response.penny_drop === 1) {
            registerForm.setValue("txtAccHolder", response.account_holder_name);
            if (!response.account_holder_name) {
              setAccNameDisabled(false);
            } else {
              setAccNameDisabled(true);
            }
            setVisibleAccHolder(true);
            setVisibleSubmitBtn(true);
            setVisibleVerifyBtn(false);
          } else if (response.penny_drop === 0) {
            setVisibleAccHolder(true);
            setAccNameDisabled(false);
            setVisibleSubmitBtn(true);
            setVisibleVerifyBtn(false);
          } else {
            setVisibleAccHolder(false);
            setVisibleVerifyBtn(true);
            setVisibleSubmitBtn(false);
          }

          if (response.micr_fetched === 1) {
            setVisibleMICR(false);
            setVisibleSubmitBtn(true);
            setVisibleVerifyBtn(false);
            registerForm.setValue("txtMICE", response.bank_micr);
          } else if (response.micr_fetched === 0) {
            setVisibleMICR(true);
            if (micrRef && micrRef.current) micrRef.current.focus();
            setVisibleSubmitBtn(true);
            setVisibleVerifyBtn(false);
          } else {
            setVisibleMICR(false);
            setVisibleVerifyBtn(true);
            setVisibleSubmitBtn(false);
          }

          if (response.bank_fetched === 0) {
            setVisibleBankMainAddress(true);
            setVisibleBankName(true);
          } else if (response.bank_fetched === 1 && response.bank_pincode_fetched === 0) {
            setVisibleBankMainAddress(true);
            setVisibleBankName(true);
            setDisabledFields(["txtAccType", "txtAccNo", "txtIFC_code", "txtBank", "txtBuilding", "txtStreet", "txtLocality"]);
            registerForm.setValue("txtBank", response.bank_name);
            registerForm.setValue("txtBuilding", response.bank_addr1);
            registerForm.setValue("txtStreet", response.bank_addr2);
            registerForm.setValue("txtLocality", response.bank_addr3);
            registerForm.setValue("bankState", response.bank_state);
            registerForm.setValue("bankCity", response.bank_city);
          } else {
            setVisibleBankMainAddress(false);
            setVisibleBankName(false);
            setVisibleSubmitBtn(true);
            setVisibleVerifyBtn(false);
            registerForm.setValue("txtBank", response.bank_name);
            registerForm.setValue("txtBuilding", response.bank_addr1);
            registerForm.setValue("txtStreet", response.bank_addr2);
            registerForm.setValue("txtLocality", response.bank_addr3);
            registerForm.setValue("bankPincode", response.bank_pincode);
            registerForm.setValue("bankState", response.bank_state);
            registerForm.setValue("bankCity", response.bank_city);
          }
        } else {
          if (data.invalid_fields) {
            data.invalid_fields.forEach((f) => {
              registerForm.setError(f.field, {
                message: f.error,
              });
            });
          }
          setApiMessage(response.error);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setShowPennydrop(false);
        // setLoading(false);
      });
  };

  const updateDetails = (response) => {
    setLoading(true);
    let body = {
      status: "success",
      request_id: response.digio_doc_id,
    };
    LeadService.postRekycReversePennyDrop(body)
      .then((data) => {
        if (data.success) {
          resetForm();
          setShowPD(false)
          setShowRPD(false)
          setShowDetails(true)
          setBankDetails({
            accountHolderName: data.account_holder_name,
            accountNumber: data.bank_account_no,
            accountType: data.bank_account_type,
            bankAddress1: data.bank_addr1,
            bankAddress2: data.bank_addr2,
            bankAddress3: data.bank_addr3,
            bankCity: data.bank_city,
            bankCountry: data.bank_country,
            bankIFSC: data.bank_ifsc,
            bankMICR: data.bank_micr,
            bankName: data.bank_name,
            bankPincode: data.bank_pincode,
            bankState: data.bank_state,
            micrFetched: data.micr_fetched,
            reversePennyDrop: data.reverse_penny_drop,
            matchScore: data.match_score,
            matched: data.matched,
            bankFetched: data.bank_fetched,
          })
        } else {
          setApiMessage(data.error);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  const ReversePennyDrop = () => {
    setLoading(true);
    setApiMessage("");
    LeadService.getRekycReversePennyDrop()
      .then((data) => {
        if (data.success) {
          if (data.use_sdk) {
            let options = data.sdk.options;
            options.callback = function (response) {
              if (response.hasOwnProperty("error_code")) {
                return console.log(strings.processerror);
              }
              updateDetails(response);
            };
            digio = new window.Digio(options);
            digio.init();
            digio.submit(
              data.sdk.requestId,
              data.sdk.identifier,
              data.sdk.token_id
            );
          } else {
            setApiMessage(data.error);
          }
        } else {
          setApiMessage(data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  }

  const onSubmit = (data) => {
    setLoading(true);
    const body = {
      details: {
        bank_account_type: data.txtAccType,
        bank_account_no: data.txtAccNo,
        bank_ifsc: data.txtIFC_code,
        account_holder_name: data.txtAccHolder,
        bank_micr: data.txtMICE,
        bank_name: data.txtBank,
        bank_addr1: data.txtBuilding,
        bank_addr2: data.txtStreet,
        bank_addr3: data.txtLocality,
        bank_city: data.bankCity,
        bank_state: data.bankState,
        bank_country: "India",
        bank_pincode: data.bankPincode,
        is_primary: data.markAsPrimary,
        is_dp_primary: data.markAsPrimaryInDP,
        penny_drop: 1,
      },
    };
    setApiMessage("");
    LeadService.rekycAddBankDetails(body)
      .then((data) => {
        if (data.success) {
          onSuccess();
          resetForm();
        } else {
          if (data.invalid_fields) {
            data.invalid_fields.forEach((f) => {
              registerForm.setError(f.field, {
                message: f.error,
              });
            });
          }
          setApiMessage(data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const onRPDSubmit = () => {
    setLoading(true);
    const body = {
      details: {
        bank_account_type: bankDetails.accountType,
        bank_account_no: bankDetails.accountNumber,
        bank_ifsc: bankDetails.bankIFSC,
        account_holder_name: bankDetails.accountHolderName,
        bank_micr: bankDetails.bankMICR,
        bank_name: bankDetails.bankName,
        bank_addr1: bankDetails.bankAddress1,
        bank_addr2: bankDetails.bankAddress2,
        bank_addr3: bankDetails.bankAddress3,
        bank_city: bankDetails.bankCity,
        bank_state: bankDetails.bankState,
        bank_country: "India",
        bank_pincode: bankDetails.bankPincode,
        reverse_penny_drop: bankDetails.reversePennyDrop,
        match_score: bankDetails.matchScore,
        matched: bankDetails.matched,
        micr_fetched: bankDetails.micrFetched,
        bank_fetched: bankDetails.bankFetched,
        is_primary: false,
        is_dp_primary: false,
      },
    };
    setApiMessage("");
    LeadService.rekycAddBankDetails(body)
      .then((data) => {
        if (data.success) {
          onSuccess();
          resetForm();
          setShowPD(true)
          setShowRPD(true)
          setShowDetails(false)
        } else {
          if (data.invalid_fields) {
            data.invalid_fields.forEach((f) => {
              registerForm.setError(f.field, {
                message: f.error,
              });
            });
          }
          setApiMessage(data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const handleChangePrimary = (checked) => {
    setShowDPCheckbox(checked);
    registerForm.setValue("markAsPrimaryInDP", checked);
  };

  const resetForm = () => {
    const checkbox1 = document.getElementById("markAsPrimary");
    if (checkbox1) checkbox1.checked = false;
    const checkbox2 = document.getElementById("markAsPrimaryInDP");
    if (checkbox2) checkbox2.checked = false;
    registerForm.reset({
      txtAccType: "",
      txtAccNo: "",
      txtAccHolder: "",
      txtIFC_code: "",
      txtMICE: "",
      txtBank: "",
      txtBuilding: "",
      txtStreet: "",
      txtLocality: "",
      bankPincode: "",
      bankCity: "",
      bankState: "",
      markAsPrimary: false,
      markAsPrimaryInDP: false,
    });
    setApiMessage(false);
    setDisabledFields([]);
    setShowDPCheckbox(false);
    setVisibleVerifyBtn(true);
    setVisibleSubmitBtn(false);
    setVisibleBankMainAddress(false);
    setVisibleBankName(false);
    setVisibleMICR(false);
    setVisibleAccHolder(false);
  };

  const onCancel = () => {
    setShowRPD(true);
    setShowPD(true);
    setShowDetails(false);
    setShowPenny(false);
    resetForm();
    cancelButtonAction();
  };

  const VerifyAccount = () => {
    setShowRPD(false);
    setShowPD(false);
    setShowPenny(true);
  }

  return (
    <div className="loaderContainers">
      <AlertBox
        show={show}
        title={
          <>
            <div className="ml-4 mt-3 w-75">
              <h5 className="update-label">{strings.rekyc_update_label}</h5>
            </div>
            <hr className="mb-2" />
          </>
        }
        showCancelIcon={true}
        cancelButtonAction={onCancel}
        classes={"box-sectionx"}
      >
        <div className="scrollY overflow-auto container" id="pd-50">
          {showPenny ?
            <Form
              autoFocusElement="txtAccTye"
              reactHookForm={registerForm}
              onSubmit={
                visibleVerifyBtn
                  ? verifyBank
                  : visibleSubmitBtn
                    ? onSubmit
                    : () => { }
              }
            >
              <div className="row pl-3">
                <div className="col-md-12">
                  <RadioGroup
                    tabIndex="111"
                    name="txtAccType"
                    mainLabel={strings.acctype}
                    options={RadioGroupOptions.ACC_TYPE || []}
                    mainLabelClass="acclbl rad_col"
                    errorClass="text-danger errorfont mh-18 radio_error"
                    onChange={() => setApiMessage(false)}
                  />
                </div>
                <div className="col-md-6 pl-0 pr-0 pt-2">
                  <Input
                    reference={accnoRef}
                    tabIndex="112"
                    label={strings.accno}
                    labelClass="lblz"
                    classes="material-textfield"
                    inputClass="addinput inpzz"
                    type="number"
                    name="txtAccNo"
                    mainClass="mt-2"
                    maxLength={50}
                    disabled={disabledFields.includes("txtAccNo")}
                    errorclass="text-danger errorfont mh-18"
                    onChange={() => setApiMessage(false)}
                  />
                </div>
                <div className="col-md-6 pl-0 pr-0 pt-2">
                  <Input
                    tabIndex="113"
                    label={strings.ifc}
                    labelClass="lblz"
                    mainClass="mt-2"
                    classes="material-textfield"
                    type="text"
                    name="txtIFC_code"
                    inputClass="addinput inpzz txt-up"
                    maxLength={50}
                    disabled={disabledFields.includes("txtIFC_code")}
                    errorclass="text-danger errorfont mh-18"
                    onChange={() => setApiMessage(false)}
                  />
                </div>
                {visibleAccHolder ? (
                  <div className="col-md-6 pl-0 pr-0 pt-2">
                    <Input
                      tabIndex="114"
                      mainclass="col-md-6 pb-2"
                      label={strings.ac_holdername}
                      labelClass="lblz"
                      classes="material-textfield"
                      inputClass="addinput inpzz"
                      name="txtAccHolder"
                      type="text"
                      maxLength={50}
                      errorclass="text-danger errorfont mh-18"
                      disabled={accNameDisabled || false}
                    />
                  </div>
                ) : null}
                {visibleMICR ? (
                  <div className="col-md-6 pl-0 pr-0 pt-2">
                    <Input
                      reference={micrRef}
                      tabIndex="115"
                      mainclass="col-md-6 pb-2"
                      label={strings.micr}
                      labelClass="lblz"
                      classes="material-textfield"
                      type="number"
                      name="txtMICE"
                      inputClass="addinput inpzz"
                      maxLength={50}
                      disabled={disabledFields.bank_micr || false}
                      errorclass="text-danger errorfont mh-18"
                    />
                  </div>
                ) : null}
                {visibleBankName ? (
                  <div className="col-md-6 pl-0 pr-0 pt-2">
                    <Input
                      tabIndex="116"
                      mainclass="col-md-6 pb-2"
                      label={strings.bankname}
                      labelClass="lblz"
                      classes="material-textfield"
                      inputClass="addinput inpzz"
                      type="text"
                      name="txtBank"
                      maxLength={50}
                      disabled={disabledFields.includes("txtBank")}
                      errorclass="text-danger errorfont mh-18"
                    />
                  </div>
                ) : null}
                {visibleBankMainAddress ? (
                  <React.Fragment>
                    <div className="col-md-12">
                      <label className="bankadd pb-1">
                        {strings.bankaddress}
                      </label>
                    </div>
                    <div className="col-md-6 pl-0 pr-0 pb-2">
                      <Input
                        tabIndex="117"
                        mainclass="col-md-4"
                        label={strings.buildingno}
                        placeholder=""
                        labelClass="lblz"
                        classes="material-textfield"
                        inputClass="addinput inpzz"
                        type="text"
                        name="txtBuilding"
                        maxLength={50}
                        disabled={disabledFields.includes("txtBuilding")}
                        errorclass="text-danger errorfont mh-18"
                      />
                    </div>
                    <div className="col-md-6 pl-0 pr-0 pb-2">
                      <Input
                        tabIndex="118"
                        mainclass="col-md-4"
                        label={strings.street}
                        placeholder=""
                        labelClass="lblz"
                        classes="material-textfield"
                        inputClass="addinput inpzz"
                        type="text"
                        name="txtStreet"
                        maxLength={50}
                        disabled={disabledFields.includes("txtStreet")}
                        errorclass="text-danger errorfont mh-18"
                      />
                    </div>
                    <div className="col-md-6 pl-0 pr-0 pb-2">
                      <Input
                        tabIndex="119"
                        mainclass="col-md-4"
                        label={strings.bankaddressarea}
                        placeholder=""
                        labelClass="lblz"
                        classes="material-textfield"
                        inputClass="addinput inpzz"
                        type="text"
                        name="txtLocality"
                        maxLength={50}
                        disabled={disabledFields.includes("txtLocality")}
                        errorclass="text-danger errorfont mh-18"
                      />
                    </div>
                    <div className="col-md-6 pl-0 pr-0 pb-2">
                      <PincodeBox
                        tabIndex="120"
                        mainClass="col-md-4 pb-2"
                        placeholder=""
                        inputclass="addinput inpzz"
                        type="number"
                        name="bankPincode"
                        stateName="bankState"
                        cityName="bankCity"
                        label="Pincode"
                        labelClass="lblz"
                        errorclass="text-danger errorfont pl-3"
                      />
                    </div>
                    <div className="col-md-6 pl-0 pr-0">
                      <Input
                        tabIndex="121"
                        mainclass="col-md-4"
                        label={strings.city}
                        placeholder=""
                        labelClass="lblz"
                        classes="material-textfield"
                        inputClass="addinput inpzz"
                        type="text"
                        name="bankCity"
                        maxLength={50}
                        disabled={disabledFields}
                        errorclass="text-danger errorfont mh-18"
                      />
                    </div>
                    <div className="col-md-6 pl-0 pr-0">
                      <Input
                        tabIndex="122"
                        mainclass="col-md-4"
                        label={strings.state}
                        placeholder=""
                        labelClass="lblz"
                        classes="material-textfield"
                        inputClass="addinput inpzz"
                        type="text"
                        name="bankState"
                        maxLength={50}
                        disabled={disabledFields}
                        errorclass="text-danger errorfont mh-18"
                      />
                    </div>
                    {visibleSubmitBtn ? (
                      <div className="col-md-12 pl-0 pr-0">
                        <Checkbox
                          tabIndex="123"
                          label={strings.confirm_mark_primary}
                          labelClass="sameaspers mb-0 -mt-2"
                          classes="sameasperchk mb-2"
                          name="markAsPrimary"
                          inputClass="mt-0"
                          onChange={handleChangePrimary}
                        />
                      </div>
                    ) : null}
                    {showDPCheckbox ? (
                      <div className="col-md-12 pl-0 pr-0">
                        <Checkbox
                          tabIndex="124"
                          label={strings.mark_as_primary_in_dp}
                          labelClass="sameaspers mb-0 -mt-2"
                          classes="sameasperchk mb-2"
                          mainClass="sameasprimedp"
                          inputClass="mt-0"
                          name="markAsPrimaryInDP"
                        />
                      </div>
                    ) : null}
                  </React.Fragment>
                ) : null}
              </div>
              {apiMessage ? (
                <div className="row justify-content-center align-items-center">
                  <div className="text-danger text-center">
                    <label id="txterror" name="txterror">
                      {apiMessage}
                    </label>
                  </div>
                </div>
              ) : null}
              <div className="mr-0 row justify-content-end align-items-center">
                <Button
                  type="reset"
                  classes1="cancelbtn mt-0"
                  click={onCancel}
                  label="Cancel"
                />
                {visibleVerifyBtn && (
                  <Button type="submit"
                    tabIndex="125"
                    classes1="vbtns"
                    label="VERIFY" />
                )}
                {visibleSubmitBtn && (
                  <Button
                    tabIndex="126"
                    type="submit"
                    classes1="vbtns subbtn mt-0"
                    label="SUBMIT"
                  />
                )}
              </div>
            </Form>
            : null}

          {showDetails ?
            <Form
              autoFocusElement="txtAccTye"
              reactHookForm={registerForm}
              onSubmit={onRPDSubmit}
            >
              <div className="row col-md-12 mx-auto">
                <div className="col-md-6 pt-2">
                  <label>Account Type</label>
                  <input className="addinput inpzz" type="text" value={bankDetails.accountType} readOnly />
                </div>
                <div className="col-md-6 pt-2">
                  <label>Account Number</label>
                  <input className="addinput inpzz" type="text" value={bankDetails.accountNumber} readOnly />
                </div>
                <div className="col-md-6  pt-2">
                  <label>Bank IFSC</label>
                  <input className="addinput inpzz" type="text" value={bankDetails.bankIFSC} readOnly />
                </div>
                <div className="col-md-6  pt-2">
                  <label>Account Holder Name</label>
                  <input className="addinput inpzz" type="text" value={bankDetails.accountHolderName} readOnly />
                </div>
                <div className="col-md-6  pt-2">
                  <label>Match Score</label>
                  <input className={bankDetails.matched === false ? "addinput inpzz bg-red" : "addinput inpzz"} type="text" value={bankDetails.matchScore} readOnly />
                </div>
                <div className="col-md-6  pt-2">
                  <label>Matched</label>
                  <input className={bankDetails.matched === false ? "addinput inpzz bg-red" : "addinput inpzz"} type="text" value={bankDetails.matched} readOnly />
                </div>
              </div>

              {apiMessage ? (
                <div className="row justify-content-center align-items-center">
                  <div className="text-danger text-center">
                    <label id="txterror" name="txterror">
                      {apiMessage}
                    </label>
                  </div>
                </div>
              ) : null}
              <div className="mr-0 row justify-content-end align-items-center">
                <Button
                  type="reset"
                  classes1="cancelbtn mt-3"
                  click={onCancel}
                  label="Cancel"
                />
                <Button
                  tabIndex="126"
                  type="submit"
                  classes1="vbtns subbtn mt-3"
                  label="SUBMIT"
                  click={onRPDSubmit}
                />
              </div>
            </Form>
            : null}

          <div className="row">
            {showRPD ?
              <div className="col-md-6 pt-2 pb-2">
                <div className="box-ad">
                  <div className="justify-center pb-4">
                    <img src={process.env.PUBLIC_URL + "/img/upi.jpg"} width="100px" height="100px" className="" />
                  </div>
                  <h5>
                    {strings.reverse_penny_drop_head}
                  </h5>
                  <p>{strings.reverse_penny_drop_note}</p>
                  <a
                    className="e-sign"
                    style={{ textDecoration: "none" }}
                    onClick={ReversePennyDrop}
                    href="# "
                  >
                    {strings.verify_now} &#40;{strings.recommended}&#41;
                  </a>
                </div>
              </div>
              : null}
            {showPD ?
              <div className="col-md-6 pt-2 pb-2">
                <div className="box-ad">
                  <div className="justify-center pb-4">
                    <img src={process.env.PUBLIC_URL + "/img/penny-drop.png"} width="100px" height="100px" className="" />
                  </div>
                  <h5>
                    {strings.penny_drop_head}
                  </h5>
                  <p>{strings.penny_drop_note}</p>
                  <a
                    className="courier"
                    style={{ textDecoration: "none" }}
                    onClick={VerifyAccount}
                    href="# "
                  >
                    {strings.verify_now}
                  </a>
                </div>
              </div> : null}
            {showRPD ?
              <div className="col-md-12 mt-3">
                <div className="alert alert-success" role="alert">
                  {strings.primary_bank_note}
                </div>
              </div>
              : null}
          </div>
        </div>
        {showPennydrop && (
          <div className="loaderContainer">
            <PennydropSpin />
          </div>
        )}
      </AlertBox>
    </div>
  );
};
export { BankDetails };
